

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import { VENDOR_TYPES } from "@/constants";
import { BrokerVendors } from "@/models/broker-vendor.model";
import { HollowDotsSpinner } from "epic-spinners";
import _ from 'lodash'

@Component({ components: { HollowDotsSpinner } })
export default class ManageVendors extends Vue {
  public vendors = new BrokerVendors();

  public editUserData = {
    vendorName: null,
    userName: null,
    password: null,
    vendorWebsite: null,
    description: null,
    editing: false
  };
  public inProcess = false;
  public modalInProcess = false;
  public noVendors = true;

  public modalAction = "Add";
  public vendorTypes = VENDOR_TYPES;
  public ausVendorSubTypes = ['Fannie Mae', 'Freddie Mac'];
  public otherIndex = 0;
  public dotLoader = false;
  public vendorData = [];
  public newVendorsData: any = {
    fannieMae: {},
    freddieMac: {}
  };
  public ausVendorType = null;
  public addVendorType = null;
  public subAusType = null;
  public vendorEditIndex = null;
  public isVendorEdit = false;
  public confirmSingleTypeVendorEditMessage = null;
  public confirmationResult = null;
  public searchName = "";
  public vendorLoader = false;
  public loadMoreScroll = false;
  public manageVendorLen = 0;
  public searchByType=null;
  public searchString='';

public vendorsShowingPassword=[]

public togglePasswordDisplay(role) {
    this.vendorsShowingPassword = this.vendorsShowingPassword.includes(
      role._id
    )
      ? this.vendorsShowingPassword.filter((id) => id != role._id)
      : [role._id, ...this.vendorsShowingPassword];
  }

public async getVendorsList(mountCall: Boolean = false) {
   try {
      this.dotLoader=true;
      let response = await Axios.post(
        BASE_API_URL +
          "broker/getVendorsList/",
          {
            userId: this.$userId,
            userType: this.$userType,
            brokerId: this.$brokerId,
            fieldName: this.searchByType,
            searchString:
            this.searchString != "" && this.searchString != null
              ? this.searchString
              : null,
          })
          // this.$userId +
          // "/" +
          // this.$userType +
          // "/" +
          // this.$brokerId);
      if (this.$userType != "Broker") {
        response.data.brokerVendors.forEach(element => {
          if (element.vendorType === 'AUS Vendor' || element.vendorType === 'Credit Vendor') return;

          if (
            element.addedByUserType == "Broker" ||
            element.addedByUserType == "Super Admin"
          ) {
            element.brokerVendorReferenceId = element._id;
            delete element._id;
            element.userName = null;
            element.password = null;
          } else if (element.addedBy != this.$userId) {
            element.userName = null;
            element.password = null;
          }
        });
      } else {
        response.data.brokerVendors.forEach(element => {
          if (this.$userType == "Broker") {
            if (
              element.addedByUserType == "Admin Staff" ||
              element.addedByUserType == "MLO"
            ) {
              element.userName = null;
              element.password = null;
            }
          } else {
            if (element.addedBy != this.$userId) {
              element.userName = null;
              element.password = null;
            }
          }
        });
      }
      this.vendorData = [];
      response.data.brokerVendors.forEach(element => {
        if (element.vendorType != "Loan Sifter") this.vendorData.push(element);
      });
      if(this.searchString==null||this.searchString==""){
      this.manageVendorLen = this.vendorData.length;
      }
      this.dotLoader = false;
    } catch (error) {
      this.dotLoader = false;
      console.log(error.response, "error");
    }
  }
   public getValueOnChange(){
  if(this.searchByType ==null){
     this.vendorData=[]
     this.searchByType = null;
     this.searchString = null;
     this.getVendorsList();
}
}

  public resolveVendorTypes() {
    try {
      this.ausVendorSubTypes = ['Fannie Mae', 'Freddie Mac'];
      this.vendorTypes = ["Appraisal"];

      // Find vendors by current user and vendorType = 'Credit Vendor'
      const creditVendorsAddedBySelf = 
        this.vendorData.filter(vendor => vendor.addedBy == this.$userId && vendor.vendorType == 'Credit Vendor');
      if (creditVendorsAddedBySelf.length === 0) {
        this.vendorTypes.push('Credit Vendor');
      }

      this.ausVendorType = null;
      const ausVendorsAddedBySelf = 
        this.vendorData.filter(vendor => vendor.addedBy == this.$userId && vendor.vendorType == 'AUS Vendor');
      if (ausVendorsAddedBySelf.length < 2) {
        this.vendorTypes.push('AUS Vendor');
        
        const vendorName = _.get(_.head(ausVendorsAddedBySelf), 'vendorName', null);
        this.ausVendorType = vendorName;

        if (_.isEmpty(vendorName)) {
          this.ausVendorSubTypes = ['Fannie Mae', 'Freddie Mac'];
        } else {
          if (vendorName === 'Fannie Mae') {
            this.ausVendorSubTypes = ['Freddie Mac'];
          } else {
            this.ausVendorSubTypes = ['Fannie Mae'];
          }
        }
      }

      this.vendorTypes.sort();
      this.vendorTypes.push("Other");
    } catch (error) {
      console.log(error);
    }
  }

  public addNewVendorsData() {
    this.newVendorsData = {
      fannieMae: {},
      freddieMac: {}
    };
  }

  public editVendor(index) {
    try {
      this.isVendorEdit = true;
      this.modalAction = "Update";
      this.addNewVendorsData();
      if (this.vendorData[index].vendorType == "AUS Vendor") {
        this.ausVendorType = this.vendorData[index].vendorName;
        if (this.vendorData[index].vendorName == "Fannie Mae")
          this.newVendorsData.fannieMae = this.vendorData[index];
        if (this.vendorData[index].vendorName == "Freddie Mac")
          this.newVendorsData.freddieMac = this.vendorData[index];
      } else
        this.newVendorsData = {
          fannieMae: {},
          freddieMac: {},
          ...this.vendorData[index]
        };
      this.vendorData = JSON.parse(JSON.stringify(this.vendorData));
      this.vendorTypes = [];
      this.vendorTypes.push(this.vendorData[index].vendorType);
      this.addVendorType = this.vendorData[index].vendorType;
      this.subAusType = this.vendorData[index].vendorName;
      this.vendorEditIndex = index;
      this.$modal.show("addVendorModal");
    } catch (error) {
      console.log(error);
    }
  }

  public async addAndEditVendor() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        return;
      }
      this.modalInProcess = true;
      let data = {};
      if (this.isVendorEdit) {
        if (this.addVendorType == "AUS Vendor") {
          this.newVendorsData.vendorType = "AUS Vendor";
          let ausVendorType =
            this.ausVendorType == "Fannie Mae" ? "fannieMae" : "freddieMac";
          this.newVendorsData[ausVendorType].updatedBy = this.$userId;
          this.newVendorsData[ausVendorType].updatedOn = new Date();
        } else {
          this.newVendorsData.updatedBy = this.$userId;
          this.newVendorsData.updatedOn = new Date();
        }
        this.newVendorsData.userId = this.$userId;
        this.newVendorsData.userType = this.$userType;
        this.newVendorsData.brokerId = this.$brokerId;

        if (this.newVendorsData.brokerVendorReferenceId) {
          if (!this.newVendorsData._id) {
            if (
              (!this.newVendorsData.userName ||
                this.newVendorsData.userName == "") &&
              (!this.newVendorsData.password ||
                this.newVendorsData.password == "")
            ) {
              this.modalInProcess = false;
              this.cancelEditUser();
              return;
            }
          } else {
            //Left this condition after discussing with maam that if mlo/admin-staff once modified broker vendor's credentials and save it then a copy of broker's vendor will be creted for him and later if he empties his vendor's credentials then also it will be there and will not be removed and hencce in such situation also he will se his vendor not his broker's vewndor.
          }
        }

        let confirmEditVendor = null;
        if (
          this.addVendorType == "LOS" ||
          this.addVendorType == "Credit Vendor"
        ) {
          let confirmDeletePreviousVendor = null;
          let allSameTypeVendor = null;
          if (this.newVendorsData._id) {
            allSameTypeVendor = this.vendorData.find(
              v =>
                v.vendorType == this.addVendorType &&
                v.addedBy == this.$userId &&
                v._id != this.newVendorsData._id
            );
          } else {
            allSameTypeVendor = this.vendorData.find(
              v =>
                v.vendorType == this.addVendorType && v.addedBy == this.$userId
            );
          }

          if (allSameTypeVendor) {
            await this.$dialog
              .confirm(
                "You already have added " +
                  this.addVendorType +
                  " and any user can have only one " +
                  this.addVendorType +
                  " . If you want to use it, your previous " +
                  this.addVendorType +
                  " will be deleted.Do you want to continue?"
              )
              .then(e => {
                confirmDeletePreviousVendor = allSameTypeVendor._id;
              })
              .catch(f => {
                confirmDeletePreviousVendor = "false";
              });
          }
          if (confirmDeletePreviousVendor == "false") {
            this.modalInProcess = false;
            this.cancelEditUser();
            return;
          }

          if (
            confirmDeletePreviousVendor &&
            confirmDeletePreviousVendor != "false"
          ) {
            this.newVendorsData[
              "deletePreviousVendor"
            ] = confirmDeletePreviousVendor;
          }
        }

        if (this.newVendorsData.brokerVendorReferenceId) {
          let originalVendor = this.vendorData.find(
            e =>
              e.brokerVendorReferenceId ==
              this.newVendorsData.brokerVendorReferenceId
          );
          if (originalVendor.vendorName != this.newVendorsData.vendorName) {
            await this.$dialog
              .confirm(
                "You are changing vendor name.Are you sure want to add new vendor?"
              )
              .then(e => {
                confirmEditVendor = "true";
              })
              .catch(f => {
                confirmEditVendor = "false";
              });
          }
          if (confirmEditVendor == "false") {
            this.modalInProcess = false;
            this.cancelEditUser();
            return;
          }
        }

        data = this.newVendorsData;
      } else {
        let details = {
          addedBy: this.$userId,
          addedByUserType: this.$userType,
          brokerId: this.$userType == "Broker" ? this.$userId : this.$brokerId,
          createdOn: new Date(),
          vendorType: this.addVendorType
        };
        if (this.newVendorsData.fannieMae.userName) {
          this.newVendorsData.fannieMae = {
            vendorName: "Fannie Mae",
            ...details,
            ...this.newVendorsData.fannieMae
          };
        }
        if (this.newVendorsData.freddieMac.userName) {
          this.newVendorsData.freddieMac = {
            vendorName: "Freddie Mac",
            ...details,
            ...this.newVendorsData.freddieMac
          };
        }
        this.newVendorsData = { ...details, ...this.newVendorsData };
        data = this.newVendorsData;
      }

      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/addBrokerVendor",
        data);
      this.vendorData = [];
      response.data.forEach(element => {
        if (element.vendorType != "Loan Sifter") this.vendorData.push(element);
      });
      this.$modal.hide("addVendorModal");
      this.$snotify.success(
        this.isVendorEdit
          ? "Vendor updated successfully"
          : "Vendor added successfully"
      );
      this.isVendorEdit = false;
      this.addVendorType = null;
      this.subAusType = null;
      this.modalInProcess = false;
      this.addNewVendorsData();
      await this.getVendorsList();
    } catch (error) {
      this.modalInProcess = false;
      console.log(error);
    }
  }

  public openAddVendorModal() {
    this.vendorTypes = VENDOR_TYPES;
    this.isVendorEdit = false;
    this.modalAction = "Add";
    this.$modal.show("addVendorModal");
  }

  public cancelEditUser() {
    this.$modal.hide("addVendorModal");
    this.addVendorType = null;
    this.subAusType = null;
    this.addNewVendorsData();

    this.editUserData = {
      vendorName: null,
      userName: null,
      password: null,
      vendorWebsite: null,
      description: null,
      editing: false
    };
  }

  get userType() {
    return this.$store.state.sessionObject.type;
  }

  public allowEditVendors(vendor) {
    if (this.userType == "Broker") {
      //Broker will see all vendors added by any user but will be able to edit only to those which was added by him or superadmin only.
      if (
        vendor.addedBy == this.$userId ||
        vendor.addedByUserType == "Super Admin"
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.userType == "Admin Staff") {
      //Admin Staff will see all vendors added by any user but will be able to edit only to those which was added by him and he will able to edit only username and password of those vendors which were added by broker or superadmin but it will be entered in db as new entry (i.e copy of original vendor with name and password changed) and it won't change original vendor.
      if (vendor.addedBy == this.$userId || vendor.brokerVendorReferenceId) {
        return true;
      }
    } else {
      //Mlo will see only those vendors added by him/her or the broker but will be able to edit only to those which was added by him and he will able to edit only username and password of those vendors which were added by broker or superadmin but it will be entered in db as new entry (i.e copy of original vendor with name and password changed) and it won't change original vendor.
      return true;
    }
  }
  //   public async searchByName() {
  //   try {
  //     if (this.searchName) {
  //       this.vendorData = this.searchList.filter(user => {
  //         if (
  //           user.vendorType
  //             .toLowerCase()
  //             .includes(this.searchName.toLowerCase()) ||
  //           user.vendorName.toLowerCase().includes(
  //             this.searchName.toLowerCase()
  //           )
  //         ) {
  //           return user;
  //         }
  //       });
  //     } else {
  //       this.vendorData = this.allVendors;
  //     }
  //   } catch (error) {}
  // }
  
  // public async getTotalManageVendorCount() {
  //   try {
  //     let response = await Axios.post(
  //       BASE_API_URL + "broker/getTotalManageVendorCount/",
  //       {
  //         userId:this.$userId,
  //       },
  //       {
  //
  //       }
  //     );
  //     //this.manageVendorLen = response.data.manageVendorLen;
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

 beforeMount() {
 this.getVendorsList();
    
  }
}
