export class BrokerVendors {
    constructor() {
        this.los = new Array<any>();
        this.credit_vendor = new Array<any>();
        this.appraisal = new Array<any>();
        this.aus_vendor = [new AUSVendor()];
        this.loan_sifter = [new Credentials];
        this.others =new Array<any>();
    }

    public los: Array<any>;
    public credit_vendor: Array<any>;
    public appraisal: Array<any>;
    public aus_vendor: Array<AUSVendor>;
    public loan_sifter : Array<Credentials>;
    public others :Array<any>
}

class AUSVendor {
    public fannieMae = new Credentials();
    public freddieMac = new Credentials();
}

class Credentials {
    public userName: string;
    public password: string
}